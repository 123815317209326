import {useEffect, useMemo, useState} from 'react'
import {Trans, WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import notebook from '../../assets/images/notebook.png'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import useScrollPosition from '../../hooks/useScroll'
import useScrollAfterComponent from '../../hooks/useScrollAfterComponent'
import useScrollBeforeComponent from '../../hooks/useScrollBeforeComponent'
import useScrollingDown from '../../hooks/useScrollingDown'

import styles from './Marketplace.module.scss'

interface IValue {
  id: number
  title: string
  description: string
}

const COMPONENT_ID = 'marketplace'

const Marketplace = ({t}: WithTranslation) => {
  const values = useMemo<IValue[]>(
    () => [
      {
        id: 1,
        title: t('marketplace.values.tokens'),
        description: t('marketplace.values.tokensDescription'),
      },
      {
        id: 2,
        title: t('marketplace.values.security'),
        description: t('marketplace.values.securityDescription'),
      },
      {
        id: 3,
        title: t('marketplace.values.wallet'),
        description: t('marketplace.values.walletDescription'),
      },
      {
        id: 4,
        title: t('marketplace.values.paymentGateway'),
        description: t('marketplace.values.paymentGatewayDescription'),
      },
      {
        id: 5,
        title: t('marketplace.values.display'),
        description: t('marketplace.values.displayDescription'),
      },
      {
        id: 6,
        title: t('marketplace.values.invertorsDashboard'),
        description: t('marketplace.values.invertorsDashboardDescription'),
      },
      {
        id: 7,
        title: t('marketplace.values.developersDashboard'),
        description: t('marketplace.values.developersDashboardDescription'),
      },
    ],
    [t],
  )

  const [animationEnabled, enableAnimation] = useState<boolean>(false)
  const isScrollYBeforeComponent = useScrollBeforeComponent(COMPONENT_ID)
  const isScrollYAfterComponent = useScrollAfterComponent(COMPONENT_ID)
  const topComponentPositionPassed = useScrollPosition(COMPONENT_ID, 'top', 400)
  const isScrollingDown = useScrollingDown()

  useEffect(() => {
    if (isScrollYBeforeComponent && animationEnabled) enableAnimation(false)
    else if (isScrollingDown && topComponentPositionPassed && !animationEnabled)
      enableAnimation(true)
  }, [topComponentPositionPassed, isScrollingDown, isScrollYBeforeComponent])

  return (
    <BannerSection className={styles.section} id={COMPONENT_ID}>
      <BannerContainer
        className={classNames(styles.container, {
          [styles.scrollable]: !isScrollYBeforeComponent && !isScrollYAfterComponent,
        })}
        layout="column">
        <div
          className={classNames(styles.first, {
            [styles.hidden]:
              !isScrollYAfterComponent && (!animationEnabled || isScrollYBeforeComponent),
            [styles.animate]: animationEnabled,
          })}>
          <span className={styles.title}>
            <Trans i18nKey="marketplace.title">
              Brindamos un <strong>marketplace</strong> con
            </Trans>
            :
          </span>
          <img src={notebook} />
        </div>
        <div className={styles.second}>
          {values.map(value => (
            <div key={value.id} className={styles.value}>
              <span className={styles.title}>{value.title}</span>
              <span className={styles.subtitle}>{value.description}</span>
            </div>
          ))}
        </div>
      </BannerContainer>
    </BannerSection>
  )
}

export default withTranslation()(Marketplace)
