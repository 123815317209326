export const defaultSettings = {
  speed: 500,
  slidestoshow: 1,
  spaceBetween: 20,
  autoplay: false,
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    400: {
      slidesPerView: 1.5,
      spaceBetween: 20,
    },
    480: {
      slidesPerView: 1.8,
      spaceBetween: 20,
    },
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    800: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    960: {
      slidesPerView: 3.25,
      spaceBetween: 20,
    },
    1120: {
      slidesPerView: 3.5,
      spaceBetween: 20,
    },
    1380: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
  },
}

export const sliderBannerSettings = {
  speed: 5000,
  slidestoshow: 5,
  spaceBetween: 0,
  autoplay: {
    delay: 0,
  },
  loop: true,
  breakpoints: {
    320: {
      slidesPerView: 2,
    },
    400: {
      slidesPerView: 2.5,
    },
    480: {
      slidesPerView: 3,
    },
    640: {
      slidesPerView: 3.5,
    },
    800: {
      slidesPerView: 4,
    },
    960: {
      slidesPerView: 5,
    },
  },
}

export const partnersSliderSettings = {
  speed: 5000,
  slidestoshow: 1,
  spaceBetween: 0,
  autoplay: {
    delay: 0,
  },
  loop: true,
  breakpoints: {
    320: {
      slidesPerView: 2.5,
    },
    400: {
      slidesPerView: 3,
    },
    800: {
      slidesPerView: 3.25,
    },
    960: {
      slidesPerView: 3.5,
    },
    1100: {
      slidesPerView: 4.5,
    },
    1200: {
      slidesPerView: 5,
    },
    1300: {
      slidesPerView: 6,
    },
    1400: {
      slidesPerView: 7,
    },
  },
}
export const teamSliderSettings = {
  speed: 4000,
  slidestoshow: 1,
  spaceBetween: 0,
  autoplay: {
    delay: 0,
  },
  loop: true,
  breakpoints: {
    320: {
      slidesPerView: 1.5,
      spaceBetween: 40,
    },
    450: {
      slidesPerView: 2,
      spaceBetween: 40,
    },
    600: {
      slidesPerView: 2.5,
      spaceBetween: 60,
    },
    800: {
      slidesPerView: 2.75,
      spaceBetween: 80,
    },
    960: {
      slidesPerView: 3,
      spaceBetween: 100,
    },
    1100: {
      slidesPerView: 3.25,
      spaceBetween: 110,
    },
    1200: {
      slidesPerView: 3.5,
      spaceBetween: 100,
    },
  },
}
