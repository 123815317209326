import {useMemo} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'

import building from '../../assets/images/building-under-construction.png'
import city from '../../assets/images/city-from-above.png'
import signing from '../../assets/images/signing-paper.png'
import BannerContainer from '../../containers/BannerContainer'
import Card from '../Card/Card'

import styles from './WeCareAbout.module.scss'

interface IWorry {
  id: number
  imageSrc: string
  title: string
  description: string
}

const WeCareAbout = ({t}: WithTranslation) => {
  const worries = useMemo<IWorry[]>(
    () => [
      {
        id: 1,
        title: t('weCareAbout.worries.analizeProducts.title'),
        description: t('weCareAbout.worries.analizeProducts.description'),
        imageSrc: building,
      },
      {
        id: 2,
        title: t('weCareAbout.worries.showProducts.title'),
        description: t('weCareAbout.worries.showProducts.description'),
        imageSrc: city,
      },
      {
        id: 3,
        title: t('weCareAbout.worries.laws.title'),
        description: t('weCareAbout.worries.laws.description'),
        imageSrc: signing,
      },
    ],
    [t],
  )
  return (
    <BannerContainer className={styles.container} layout="column">
      <div className={styles.header}>
        <span className={styles.title}>{t('weCareAbout.title')}:</span>
      </div>
      <div className={styles.worries}>
        {worries.map(worry => (
          <Card key={worry.id} className={styles.card} withShadow>
            <div className={styles.preview}>
              <img src={worry.imageSrc} />
            </div>
            <div className={styles.text}>
              <span className={styles.title}> {worry.title}</span>
              <span className={styles.description}>{worry.description}</span>
            </div>
          </Card>
        ))}
      </div>
    </BannerContainer>
  )
}

export default withTranslation()(WeCareAbout)
