import {Trans, WithTranslation, withTranslation} from 'react-i18next'

import {ReactComponent as Plus} from '../../assets/icons/plus.svg'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import Button from '../Button/Button'

import styles from './WelcomeBanner.module.scss'

const contact_phone = +5491156479070

const WelcomeBanner = ({t}: WithTranslation) => (
  <BannerSection className={styles.section}>
    <BannerContainer className={styles.container} layout="column">
      <span className={styles.title}>
        <Trans i18nKey="welcomeBanner.title">
          El primer ecosistema de <strong>tokenización de Real Estate</strong> para desarrolladores
        </Trans>
      </span>
      <div className={styles.footer}>
        <a
          className={styles.linkButton}
          href={`https://wa.me/${contact_phone}`}
          target="_blank"
          rel="noopener noreferrer">
          <Button className={styles.tokenizeButton} filledColor="primary">
            {t('welcomeBanner.tokenizeYourProject')}
          </Button>
        </a>
        <div className={styles.textWrapper}>
          <span className={styles.text}>
            <Plus />
            <Trans i18nKey="welcomeBanner.newSourceOfFinancing">
              Una nueva <strong>fuente de financiamiento</strong> para los desarrolladores.
            </Trans>
          </span>
          <span className={styles.text}>
            <Trans i18nKey="welcomeBanner.newInvestmentPossibility">
              Una nueva posibilidad de <strong>inversión para la comunidad</strong>.
            </Trans>
          </span>
        </div>
      </div>
    </BannerContainer>
  </BannerSection>
)

export default withTranslation()(WelcomeBanner)
