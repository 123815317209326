// import BIM from '../../components/BIM/BIM'
import CombineSections from '../../components/CombineSections/CombineSections'
import ContactUs from '../../components/ContactUs/ContactUs'
import Marketplace from '../../components/Marketplace/Marketplace'
import Partners from '../../components/Partners/Partners'
import StrengthsSlider from '../../components/StrengthsSlider/StrengthsSlider'
import Team from '../../components/Team/Team'
import TokenizeYourProjectSlider from '../../components/TokenizeYourProjectSlider/TokenizeYourProjectSlider'
import ValueLayer from '../../components/ValueLayer/ValueLayer'
import WelcomeBanner from '../../components/WelcomeBanner/WelcomeBanner'
import WhoBenefits from '../../components/WhoBenefits/WhoBenefits'

const Home = () => (
  <>
    <WelcomeBanner />
    <StrengthsSlider />
    <ValueLayer />
    <Marketplace />
    <CombineSections />
    <WhoBenefits />
    {/*<BIM />*/}
    <Partners />
    <TokenizeYourProjectSlider />
    <Team />
    <ContactUs />
  </>
)

export default Home
