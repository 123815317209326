import {ReactNode, useMemo, useState} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'

import styles from './WhoBenefits.module.scss'

type BenefitKey = 'realEstate' | 'developers' | 'investors' | 'suppliers'
interface IBenefit {
  key: BenefitKey
  description: ReactNode
}

const WhoBenefits = ({t}: WithTranslation) => {
  const [benefitSelected, selectBenefit] = useState<BenefitKey>('realEstate')
  const benefits = useMemo<IBenefit[]>(
    () => [
      {
        key: 'realEstate',
        description: <span>{t('whoBenefits.benefits.realEstateDescription')}</span>,
      },
      {
        key: 'developers',
        description: <span>{t('whoBenefits.benefits.developersDescription')}</span>,
      },
      {
        key: 'investors',
        description: (
          <>
            <span>{t('whoBenefits.benefits.investorsDescription1')}</span>
            <span>{t('whoBenefits.benefits.investorsDescription2')}</span>,
          </>
        ),
      },
      {
        key: 'suppliers',
        description: <span>{t('whoBenefits.benefits.suppliersDescription')}</span>,
      },
    ],
    [t],
  )

  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container} layout="column">
        <div className={styles.header}>
          <span className={styles.title}>{t('whoBenefits.title')}</span>
        </div>
        <div className={styles.benefits}>
          <div className={styles.column}>
            {benefits.map(benefit => (
              <span
                key={benefit.key}
                className={classNames(styles.benefit, {
                  [styles.selected]: benefitSelected === benefit.key,
                })}
                onClick={() => selectBenefit(benefit.key)}>
                {t(`whoBenefits.benefits.${benefit.key}`)}
              </span>
            ))}
          </div>
          <div className={classNames(styles.description, styles[benefitSelected])}>
            {benefits.find(benefit => benefit.key === benefitSelected)?.description}
          </div>
        </div>
      </BannerContainer>
    </BannerSection>
  )
}

export default withTranslation()(WhoBenefits)
