import {useMemo, useState} from 'react'
import {useTranslation, withTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'

import {ReactComponent as Logo} from '../../assets/brandLogos/brand-logo-white-row.svg'
import {ENGLISH_KEY_TRANSLATION, SPANISH_KEY_TRANSLATION} from '../../constants/languages'
// import {headerNavLinks} from '../../constants/navLinks'
import {PATHS} from '../../constants/paths'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import i18n from '../../utils/i18n'

import Menu from './menu/Menu'

import styles from './Header.module.scss'

const Header = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const [menuVisible, showMenu] = useState<boolean>(false)

  const languages = useMemo(
    () => [
      {key: 'es', label: t(SPANISH_KEY_TRANSLATION)},
      {key: 'en', label: t(ENGLISH_KEY_TRANSLATION)},
    ],
    [t],
  )
  const [languageSelected, selectLanguage] = useState<string>(languages[0].key)

  const handleClick = (languageChanged: string) => {
    selectLanguage(languageChanged)
    i18n.changeLanguage(languageChanged)
  }

  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container}>
        {/* {breakpoint !== 'xs' && breakpoint !== 's' ? ( */}
        <Logo className={styles.logo} onClick={() => navigate(PATHS.HOME)} />
        {/* ) : (
          <LogoMobile className={styles.logo} onClick={() => navigate(PATHS.HOME)} />
        )} */}
        <div className={styles.row}>
          {/* {headerNavLinks.map(navLink => (
            <div
              className={styles.navItem}
              key={navLink.translationKey}
              onClick={() => navigate(navLink.navigate)}>
              {t(`header.navs.${navLink.translationKey}`)}
            </div>
          ))} */}
          {/* <a
            className={styles.linkButton}
            href={CRIPTOKUANTICA_MARKET}
            target="_blank"
            rel="noopener noreferrer">
            <Button className={styles.platformButton} filledColor="primary">
              {t('header.platform')}
            </Button>
          </a> */}
          <div className={styles.language}>
            {languages.map(language => (
              <span
                className={classNames({[styles.selected]: languageSelected === language.key})}
                key={language.key}
                onClick={() => handleClick(language.key)}>
                {/* {t(`${language.label}`)} */}
                {language.key}
              </span>
            ))}
          </div>
        </div>
        {menuVisible && <Menu onChooseAction={() => showMenu(false)} />}
      </BannerContainer>
    </BannerSection>
  )
}

export default withTranslation()(Header)
