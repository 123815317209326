import {useCallback, useMemo} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import germanTrautman from '../../assets/images/team/german-trautman.png'
import juanZubiaurre from '../../assets/images/team/juan-zubiaurre.png'
import lucasGiani from '../../assets/images/team/lucas-giani.png'
import lucilaGoldenberg from '../../assets/images/team/lucila-goldenberg.png'
import nestorKreimer from '../../assets/images/team/nestor-kreimer.png'
import sergioGoldenberg from '../../assets/images/team/sergio-goldenberg.png'
import {teamSliderSettings} from '../../constants/sliderSettings'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import Slider from '../Slider/Slider'

import styles from './Team.module.scss'

interface IPerson {
  name: string
  surname: string
  jobPosition: string
  imageSrc: string
}

const Team = ({t}: WithTranslation) => {
  const people = useMemo<IPerson[]>(
    () => [
      {
        id: 1,
        name: 'Néstor',
        surname: 'Kreimer',
        jobPosition: 'Founder & Co.CEO',
        imageSrc: nestorKreimer,
      },
      {
        id: 2,
        name: 'Sergio',
        surname: 'Goldenberg',
        jobPosition: 'Founder & Co.CEO',
        imageSrc: sergioGoldenberg,
      },
      {
        id: 3,
        name: 'Germán',
        surname: 'Trautman',
        jobPosition: 'Founder & CTO',
        imageSrc: germanTrautman,
      },
      {
        id: 4,
        name: 'Lucila',
        surname: 'Goldenberg',
        jobPosition: 'CMO',
        imageSrc: lucilaGoldenberg,
      },
      {
        id: 5,
        name: 'Juan',
        surname: 'Zubiaurre',
        jobPosition: 'Master Design',
        imageSrc: juanZubiaurre,
      },
      {
        id: 6,
        name: 'Lucas',
        surname: 'Giani',
        jobPosition: 'FrontEnd Engineer',
        imageSrc: lucasGiani,
      },
      {
        id: 7,
        name: 'Néstor',
        surname: 'Kreimer',
        jobPosition: 'Founder & Co.CEO',
        imageSrc: nestorKreimer,
      },
      {
        id: 8,
        name: 'Sergio',
        surname: 'Goldenberg',
        jobPosition: 'Founder & Co.CEO',
        imageSrc: sergioGoldenberg,
      },
      {
        id: 9,
        name: 'Germán',
        surname: 'Trautman',
        jobPosition: 'Founder & CTO',
        imageSrc: germanTrautman,
      },
      {
        id: 10,
        name: 'Lucila',
        surname: 'Goldenberg',
        jobPosition: 'CMO',
        imageSrc: lucilaGoldenberg,
      },
      {
        id: 11,
        name: 'Juan',
        surname: 'Zubiaurre',
        jobPosition: 'Master Design',
        imageSrc: juanZubiaurre,
      },
      {
        id: 12,
        name: 'Lucas',
        surname: 'Giani',
        jobPosition: 'FrontEnd Engineer',
        imageSrc: lucasGiani,
      },
    ],
    [t],
  )

  const renderSlide = useCallback(
    (person: IPerson, slideClass: string) => (
      <div key={person.name + person.surname} className={classNames(slideClass, styles.person)}>
        <img src={person.imageSrc} />
        <div className={styles.line} />
        <div className={styles.text}>
          <span className={styles.name}>
            {person.name} {person.surname}
          </span>
          <span className={styles.job}>{person.jobPosition}</span>
        </div>
      </div>
    ),
    [],
  )

  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container} layout="row">
        <div className={styles.header}>
          <span className={styles.title}>{t('team.title')}</span>
        </div>
        <div className={styles.team}>
          <Slider
            className={styles.slider}
            customClasses={{
              swiperSlide: styles.swiperSlide,
              slide: styles.person,
            }}
            slides={people}
            renderSlide={renderSlide}
            settings={teamSliderSettings}
          />
        </div>
      </BannerContainer>
    </BannerSection>
  )
}

export default withTranslation()(Team)
