import {useMemo} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'

import {tokenizeYourProjectSliderSettings} from '../../constants/sliderBannerSettings'
import SliderBanner, {Slide} from '../SliderBanner/SliderBanner'

import styles from './TokenizeYourProjectSlider.module.scss'

const TokenizeYourProjectSlider = ({t}: WithTranslation) => {
  const repeatedLabels = useMemo<Slide[]>(
    () => [
      {id: 0, label: t('welcomeBanner.tokenizeYourProject')},
      {id: 1, label: t('welcomeBanner.tokenizeYourProject')},
      {id: 2, label: t('welcomeBanner.tokenizeYourProject')},
      {id: 3, label: t('welcomeBanner.tokenizeYourProject')},
      {id: 4, label: t('welcomeBanner.tokenizeYourProject')},
      {id: 5, label: t('welcomeBanner.tokenizeYourProject')},
      {id: 6, label: t('welcomeBanner.tokenizeYourProject')},
      {id: 7, label: t('welcomeBanner.tokenizeYourProject')},
      {id: 8, label: t('welcomeBanner.tokenizeYourProject')},
      {id: 9, label: t('welcomeBanner.tokenizeYourProject')},
      {id: 10, label: t('welcomeBanner.tokenizeYourProject')},
      {id: 11, label: t('welcomeBanner.tokenizeYourProject')},
      {id: 13, label: t('welcomeBanner.tokenizeYourProject')},
      {id: 14, label: t('welcomeBanner.tokenizeYourProject')},
      {id: 15, label: t('welcomeBanner.tokenizeYourProject')},
      {id: 16, label: t('welcomeBanner.tokenizeYourProject')},
    ],
    [t],
  )

  // const renderSlide = useCallback(
  //   (labelSlide: {id: number; label: string}, slideClass: string) => (
  //     <div className={classNames(slideClass, styles.labelSlide)}>
  //       <span>{labelSlide.label}</span> <Ellipses />
  //     </div>
  //   ),
  //   [t],
  // )

  return (
    <SliderBanner
      settings={tokenizeYourProjectSliderSettings}
      slides={repeatedLabels}
      className={styles.sliderBody}
    />
  )
}

export default withTranslation()(TokenizeYourProjectSlider)
