import {WithTranslation, withTranslation} from 'react-i18next'
import Slider, {Settings} from 'react-slick'
import classNames from 'classnames'

import {ReactComponent as Ellipses} from '../../assets/icons/ellipses.svg'
import BannerSection from '../../containers/BannerSection'

import styles from './SliderBanner.module.scss'

export type Slide = {id: number; label: string}
interface ISliderBanner extends WithTranslation {
  className?: string
  settings: Settings
  slides: Slide[]
}

const SliderBanner = ({className, settings, slides}: ISliderBanner) => {
  return (
    <BannerSection className={classNames(styles.section, className)}>
      <Slider {...settings} className={styles.slider}>
        {slides.map(slide => (
          <div key={slide.id} className={styles.slide}>
            <span>{slide.label}</span> <Ellipses />
          </div>
        ))}
      </Slider>
    </BannerSection>
  )
}

export default withTranslation()(SliderBanner)
