import {ReactNode, useMemo} from 'react'
import {Trans, WithTranslation, withTranslation} from 'react-i18next'

import {ReactComponent as Banknote} from '../../assets/icons/banknote.svg'
import {ReactComponent as DashboardSquare} from '../../assets/icons/dashboard-square.svg'
import {ReactComponent as DownloadTwice} from '../../assets/icons/download-twice-arrow.svg'
import {ReactComponent as ShareCircle} from '../../assets/icons/share-circle.svg'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'

import styles from './ValueLayer.module.scss'

interface IValue {
  id: number
  description: string
  logo: ReactNode
}

const ValueLayer = ({t}: WithTranslation) => {
  const values = useMemo<IValue[]>(
    () => [
      {id: 1, description: t('valueLayer.cards.card1'), logo: <DownloadTwice />},
      {id: 2, description: t('valueLayer.cards.card2'), logo: <ShareCircle />},
      {id: 3, description: t('valueLayer.cards.card3'), logo: <Banknote />},
      {id: 4, description: t('valueLayer.cards.card4'), logo: <DashboardSquare />},
    ],
    [t],
  )

  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container} layout="column">
        <div className={styles.header}>
          <span className={styles.title}>
            <Trans i18nKey="valueLayer.title">
              Generamos una <strong>capa de valor adicional</strong> para el mercado
            </Trans>
          </span>
          <span className={styles.subtitle}>{t('valueLayer.subtitle')}</span>
        </div>
        <div className={styles.cards}>
          {values.map(value => (
            <div key={value.id} className={styles.card}>
              {value.logo}
              <span>{value.description}</span>
            </div>
          ))}
        </div>
      </BannerContainer>
    </BannerSection>
  )
}

export default withTranslation()(ValueLayer)
