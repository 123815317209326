import {Settings} from 'react-slick'

export const strengthsSliderBannerSettings: Settings = {
  dots: false,
  infinite: true,
  initialSlide: 0,
  slidesToShow: 8,
  slidesToScroll: 1,
  autoplay: true,
  speed: 4000,
  autoplaySpeed: 0,
  cssEase: 'linear',
  adaptiveHeight: true,
  variableWidth: true,
  draggable: false,
  responsive: [
    {
      breakpoint: 1800,
      settings: {
        slidesToShow: 8,
      },
    },
    {
      breakpoint: 1650,
      settings: {
        slidesToShow: 7,
      },
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 4.5,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2.5,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 2.2,
      },
    },
    {
      breakpoint: 350,
      settings: {
        slidesToShow: 1.5,
      },
    },
  ],
}

export const tokenizeYourProjectSliderSettings: Settings = {
  dots: false,
  infinite: true,
  initialSlide: 0,
  slidesToShow: 7,
  slidesToScroll: 1,
  autoplay: true,
  speed: 4000,
  autoplaySpeed: 0,
  cssEase: 'linear',
  adaptiveHeight: true,
  variableWidth: true,
  draggable: false,
  responsive: [
    {
      breakpoint: 1800,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 1650,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 4.5,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3.75,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 3.25,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2.5,
      },
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1.75,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.5,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1.25,
      },
    },
    {
      breakpoint: 350,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}
