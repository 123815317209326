import {useMemo} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'

import feature1 from '../../assets/images/permutoken_feature_1.png'
import feature2 from '../../assets/images/permutoken_feature_2.png'
import feature3 from '../../assets/images/permutoken_feature_3.png'
import BannerContainer from '../../containers/BannerContainer'

import styles from './Permutoken.module.scss'

interface IWorry {
  id: number
  imageSrc: string
  title: string
  description: string
}

const Permutoken = ({t}: WithTranslation) => {
  const features = useMemo<IWorry[]>(
    () => [
      {
        id: 1,
        title: t('permutoken.features.supplierOnboarding.title'),
        description: t('permutoken.features.supplierOnboarding.description'),
        imageSrc: feature1,
      },
      {
        id: 2,
        title: t('permutoken.features.permutokenCreation.title'),
        description: t('permutoken.features.permutokenCreation.description'),
        imageSrc: feature2,
      },
      {
        id: 3,
        title: t('permutoken.features.financeWithExchange.title'),
        description: t('permutoken.features.financeWithExchange.description'),
        imageSrc: feature3,
      },
    ],
    [t],
  )
  return (
    <BannerContainer className={styles.container} layout="column">
      <>
        <div className={styles.header}>
          <span className={styles.title}>{t('permutoken.title')}</span>
          <span className={styles.subtitle}>{t('permutoken.subtitle')}</span>
        </div>
        <div className={styles.features}>
          {features.map(feature => (
            <div key={feature.id} className={styles.feature}>
              <div className={styles.preview}>
                <img src={feature.imageSrc} />
              </div>
              <div className={styles.text}>
                <span className={styles.title}>{feature.title}</span>
                <span className={styles.description}>{feature.description}</span>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.line} />
      </>
    </BannerContainer>
  )
}

export default withTranslation()(Permutoken)
