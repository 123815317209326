import {useMemo} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'

import Balancer from '../../assets/images/partners/balancer.png'
import BeInCrypto from '../../assets/images/partners/be-in-crypto.png'
import CryptoPlaza from '../../assets/images/partners/cryptoplaza.png'
import Fibree from '../../assets/images/partners/fibree.png'
import Kephi from '../../assets/images/partners/kephi.png'
import MillerAndCo from '../../assets/images/partners/miller-and-co.png'
import Rebiti from '../../assets/images/partners/rebiti.png'
import SureNed from '../../assets/images/partners/sure-ned.png'
import Turing from '../../assets/images/partners/turing.png'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'

import styles from './Partners.module.scss'

interface IPartner {
  id: number
  iconSrc: string
}

const Partners = ({t}: WithTranslation) => {
  const partners = useMemo<IPartner[]>(
    () => [
      {
        id: 1,
        iconSrc: Kephi,
      },
      {
        id: 2,
        iconSrc: Rebiti,
      },
      {
        id: 3,
        iconSrc: MillerAndCo,
      },
      {
        id: 4,
        iconSrc: BeInCrypto,
      },
      {
        id: 5,
        iconSrc: CryptoPlaza,
      },
      {
        id: 6,
        iconSrc: Turing,
      },
      {
        id: 8,
        iconSrc: Balancer,
      },
      {
        id: 9,
        iconSrc: SureNed,
      },
      {
        id: 10,
        iconSrc: Fibree,
      },
    ],
    [t],
  )

  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container} layout="column">
        <div className={styles.header}>
          <span className={styles.title}>{t('partners.title')}</span>
        </div>
        <div className={styles.partners}>
          {partners.map(partner => (
            <img key={partner.id} src={partner.iconSrc} />
          ))}
        </div>
      </BannerContainer>
    </BannerSection>
  )
}

export default withTranslation()(Partners)
