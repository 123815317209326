import {withTranslation} from 'react-i18next'

import BannerSection from '../../containers/BannerSection'
import Permutoken from '../Permutoken/Permutoken'
import WeCareAbout from '../WeCareAbout/WeCareAbout'

import styles from './CombineSections.module.scss'

const CombineSections = () => (
  <BannerSection className={styles.section}>
    <Permutoken />
    <WeCareAbout />
  </BannerSection>
)

export default withTranslation()(CombineSections)
