import {ReactNode, useMemo} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'

import {ReactComponent as Instragram} from '../../assets/icons/instagram.svg'
import {ReactComponent as LinkedIn} from '../../assets/icons/linkedin.svg'
import {ReactComponent as WhatsApp} from '../../assets/icons/whatsApp.svg'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'

import styles from './ContactUs.module.scss'

interface ISocial {
  name: string
  icon: ReactNode
  url: string
}

const contact_phone = +5491156479070

const ContactUs = ({t}: WithTranslation) => {
  const socials = useMemo<ISocial[]>(
    () => [
      {
        name: 'whatsapp',
        icon: <WhatsApp />,
        url: `https://wa.me/${contact_phone}`,
      },
      {
        name: 'linkedin',
        icon: <LinkedIn />,
        url: 'https://www.linkedin.com/company/criptokuantica/about/',
      },
      {
        name: 'instagram',
        icon: <Instragram />,
        url: 'https://www.instagram.com/criptokuantica/',
      },
    ],
    [t],
  )

  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container} layout="column">
        <div className={styles.header}>
          <span className={styles.title}>{t('contactUs.title')}</span>
        </div>
        <div className={styles.socials}>
          {socials.map(social => (
            <a key={social.name} href={social.url} target="_blank" rel="noopener noreferrer">
              {social.icon}
            </a>
          ))}
        </div>
      </BannerContainer>
    </BannerSection>
  )
}

export default withTranslation()(ContactUs)
