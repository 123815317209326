import {WithTranslation, withTranslation} from 'react-i18next'
import classNames from 'classnames'

import {ReactComponent as Logo} from '../../assets/brandLogos/logo-brand-white.svg'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'

import styles from './Footer.module.scss'

const Footer = ({t}: WithTranslation) => {
  return (
    <BannerSection className={styles.section}>
      <BannerContainer className={styles.container}>
        <div className={styles.row}>
          <div className={styles.firstRow}>
            <Logo className={styles.logo} />
          </div>
        </div>
        <div className={styles.row}>
          <span>info@criptokuantica.com</span>
        </div>
        <div className={styles.row}>
          <div className={styles.address}>
            <span>Crypto Plaza</span>
            <span>Edificio Loom, Calle de Don Ramón de la Cruz</span>
            <span>38, 28001 Madrid. España</span>
          </div>
        </div>
        <div className={styles.line} />
        <div className={classNames(styles.row, styles.copyrightRow)}>
          <div className={styles.copyright}>{t('footer.copyright')}</div>
          <div className={styles.links}>
            <a
              className={styles.link}
              href={
                'https://ipfs.io/ipfs/bafkreig6e2dwsehgx46yq5ipuuj6shaiy2hlnpvpponfaqvppz5rn72jyi'
              }
              target="_blank"
              rel="noopener noreferrer">
              {t('footer.termsAndConditions')}
            </a>
            <a
              className={styles.link}
              href={
                'https://ipfs.io/ipfs/bafkreihha2kg5arglsgattc5cmro3usbd5yk4ajjt5zhcxgeqw2jxjpwua'
              }
              target="_blank"
              rel="noopener noreferrer">
              {t('footer.privacyPolicies')}
            </a>
          </div>
        </div>
      </BannerContainer>
    </BannerSection>
  )
}

export default withTranslation()(Footer)
